import React from 'react'
import Navbar from '../components/Navbar';
const Lore = () => {
  return (
    <div className='lore'>
      <Navbar />
    </div>
  )
}

export default Lore
